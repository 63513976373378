import { render, staticRenderFns } from "./orderPatent.vue?vue&type=template&id=ed394a6a&scoped=true&"
import script from "./orderPatent.vue?vue&type=script&lang=js&"
export * from "./orderPatent.vue?vue&type=script&lang=js&"
import style0 from "./orderPatent.vue?vue&type=style&index=0&id=ed394a6a&scoped=true&lang=css&"
import style1 from "./orderPatent.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed394a6a",
  null
  
)

export default component.exports